<template>
    <div class="page invite-repairer">
        <div class="container">
            <div class="auth-header">
                <div class="logo">
                    <router-link to="/login" class="logo-text"><span style="color: #14b4bb">Parts</span><span style="color:#c1c0c0;">Search</span></router-link>
                </div>
            </div>
            <div class="invite-repairer-form text-center">
                <div class="intro-text">
                    <div class="form">
                        <div class="steps-line">
                            <ul>
                                <li class="done" style="cursor: pointer" @click="$router.push({path: '/register/supplier/find-business'})">
                                    <span class="numb">
                                        <i class="bx bxs-check-circle" style="color:#29bbc1"></i>
                                    </span>
                                    <span class="step-title">Your Business</span>
                                </li>
                                <li class="line active"></li>
                                <li class="active">
                                    <span class="numb">2</span>
                                    <span class="step-title">Business Profile</span>
                                </li>
                                <li class="line"></li>
                                <li>
                                    <span class="numb">3</span>
                                    <span class="step-title">Your Details</span>
                                </li>
                            </ul>
                        </div>
                        <br />
                        <br />
                        <h1>
                            Create your business profile for Repairers to find
                            <br />you in PartsSearch
                        </h1>
                        <p
                            class="text-center"
                        >Select the car brands your business supplier parts for.</p>

                        <form method="POST" @submit.prevent="login">
                            <div class="form-group input-field-search">
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control input-field"
                                    name="search"
                                    v-model="searchStr"
                                    placeholder="Search brands"
                                />
                                <i class="bx bx-search"></i>
                            </div>
                            <div class="brands-list">
                                <ul>
                                    <li
                                        :key="brand"
                                        v-for="brand in brandList"
                                        v-bind:class="{ active: isSelected(brand) }"
                                        @click="select(brand)"
                                    >
                                        <div class="brand-name">{{brand}}</div>
                                    </li>
                                </ul>
                            </div>
                            <br />
                            <div class="w-100 text-right row justify-content-end">
                                <a
                                    href="#"
                                    class="another-location"
                                    v-b-modal.custom-modal-add-brand
                                >
                                    <i class="bx bx-plus" style="color:#29BBC1;"></i>&nbsp;Add Another Brand Not Listed
                                </a>
                            </div>
                            <div class="form-group">
                                <button
                                    type="submit"
                                    @click="register"
                                    class="btn input-btn"
                                    :disabled="!nextAllowed"
                                >Next</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="custom-modal-add-brand" size="custom-add-brand" title="Invite Customer" @ok="addBrand" @cancel="cancelAddingBrand">
            <template v-slot:modal-backdrop class="my-backdrop"></template>
            <template v-slot:modal-header>
                <h5 class="modal-title">Add Another Brand</h5>
            </template>
            <div class="modal-invite-customer form-container">
                <form>
                    <div class="form-group">
                        <label>Name of Brand</label>
                        <input
                            type="text"
                            placeholder="Type name of Brand"
                            class="form-control input-field"
                            v-model="newBrand"
                        />
                    </div>
                </form>
            </div>
            <template v-slot:modal-footer="{ ok, cancel }">
                <div class="row w-100 justify-content-end">
                    <b-button class="input-btn cancel" @click="cancel()">Cancel</b-button>&nbsp;
                    <b-button class="input-btn" @click="ok()">Add Brand</b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
/* eslint-disable */

import _ from "lodash"
import { mapGetters } from 'vuex'

export default {
    name: "SupplierSelectBrands",
    data() {
        return {
            searchStr: "",
            selection: [],
            newBrand: '',
            registrationData: {},
        };
    },
    mounted() {
      if(localStorage.getItem('registrationData')){
        try {
          this.registrationData = JSON.parse(localStorage.getItem('registrationData'));
        } catch (e) {
          localStorage.removeItem('registrationData');
        }
      }
      this.scrollToTop()
    },
    methods: {
      select(name){
        if(!this.isSelected(name)){
          this.selection.push(name);
        } else {
          let i = this.selection.indexOf(name);
          this.selection.splice(i, 1);
        }
      },
      isSelected(name){
        return this.selection.includes(name);
      },
      addBrand(){
        if(!this.newBrand){
          return false;
        }
        this.$store.commit('addNewMake', this.newBrand)
        this.newBrand = '';
      },
      cancelAddingBrand(){
        this.newBrand = '';
      },
      register: function(){
        let brands = [];

        brands = this.selection;

        this.registrationData.brands = brands;

        localStorage.registrationData = JSON.stringify(this.registrationData);

        this.$router.push({name: 'SupplierAddWorkshoplocation'});
      },
      scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      },
    },
    computed: {
      ...mapGetters({
        'makes': 'makes',
        'truckMakes': 'truckMakes',
      }),
        nextAllowed(){
          return !!this.selection.length;
        },
        brandList() {
          let data = _.cloneDeep(this.makes)
            let self = this;
            data = _.filter(data, (item) => {
              return item.toLowerCase().includes(self.searchStr.toLowerCase());
            })
            data = _.sortBy(data, (item) => {
              return item;
            })
            return data;
        },
    }
};
</script>

<style>
@import "../../../../styles/repairer/invite/style.css";
</style>
<style lang="css" scoped>
form {
  max-width: 100%;
  margin: 0 auto;
  margin-top: 20px;
}
.form {
  max-width: 950px;
  width: 100%;
  margin: 0 auto;
}
.form .input-btn {
  max-width: 432px;
}
.form .another-location {
  font-size: 13px;
  color: #29bbc1;
}
.form .another-location i {
  font-size: 23px;
  position: relative;
  top: 4px;
}
.steps-line {
  max-width: 652px;
  width: 100%;
  margin: 0 auto;
}
.form h1 {
  text-align: center;
}
.invite-repairer-form {
  justify-content: flex-start;
  margin-top: 15px;
}
.form .input-btn {
  display: table;
  margin: 0 auto;
  margin-top: 23px;
}
.form .input-field-search {
  max-width: 248px;
  margin: 0 auto;
}

.form .brands-list ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fit, minmax(168px, 1fr));
}

.form .brands-list li {
  height: 42px;
  width: 100%;
  border: 1px solid rgba(27, 30, 56, 0.2);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  cursor: pointer;
  color: #1c1f39;
  text-align: center;
}

.form .brands-list li.active,
.form .brands-list li:hover {
  background: #29bbc1;
  color: #ffffff;
  border: none;
}
</style>
